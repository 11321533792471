import React, { useEffect, useState } from "react";
import "./global.css";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import { UserStateContext } from "../pages";

const About = (props) => {
  return (
    <>
    <UserStateContext.Consumer>
    {choose => {
            return(
              <Container>
      
              <div css={main}>
                <img
                  src={LogofromImagesFolder}
                  alt="sumadhra"
                  style={{ width: "195px", height: "80px" }}
                />
                <div>
                <Title
                    title="Adarsh Savana"
                    size="1.5vw"
                    color="#000"
                    case="uppercase"
                  />
                  <p>
                    We operate with a belief that everybody deserves a higher life.
                    Over the past 20 years Adarsh has delivered over forty comes
                    wherever thousands of happy customers reside. Our comes square
                    measure designed to produce exceptional life experiences, security
                    and price. Adarsh properties have seen the very best rate of
                    capital appreciation Associate in Nursingd have given our
                    customers an quality to possess for all times. With innovation,
                    quality, and property at the core of our work and a novel client
                    centrical approach has place United States on the league of the
                    simplest and most sure developers within the business. Our
                    experience in land acquisition, understanding the client wants,
                    execution capabilities together with the appointment of
                    architects, designers, project managers along side exceptional
                    sales and once sales service has helped United States to be
                    counted among the forerunners in assets development.
                  </p>
                </div>
              </div>
            </Container>
            )
          }}
         </UserStateContext.Consumer>
    </>
  );
};

export default About;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
  }
`;
