import * as React from "react";
import Layout from "../components/Layout";
import HeroImage from "../components/Hero";
import { css } from "@emotion/react";
import FloorPlanComponet from "../components/floorplan";
import GalleryComponent from "../components/Gallery";
import About from "../components/About";
import { ImSpinner2 } from "react-icons/im";
import Amenities from "../components/amenities";
import Table from "../components/Table";
import Title from "../components/TitleComponent";
import { Link } from "gatsby";

export const UserStateContext = React.createContext("Adarsh Savana");

const IndexPage = () => {
  const [state, setstate] = React.useState(false);
  const [choose, setchoose] = React.useState("Adarsh Savana");

  React.useEffect(() => {
    setstate(true);
  }, [state]);

  const chooseSavana = () => {
    setchoose("Adarsh Savana");
  };

  const chooseParkHeights = () => {
    setchoose("Adarsh Park heights");
  };

  const displayLogoAtLod = () => {
    return (
      <div css={load}>
        <div>
          loading..
          <br />
          <ImSpinner2 style={{ animation: `spin 0.8s linear infinite` }} />
        </div>
      </div>
    );
  };

  const displayLayout = () => {
    return (
      <UserStateContext.Provider value={choose}>
        <Layout title="Adarsh Savana Plots | Adarsh Savana Plots For Sale in Devanahalli, Bangalore" >
          <div css={HeroStyle}>
            <HeroImage />
          </div>
          <div css={chooseProjects}>
            <Title
              title="Projects to Choose"
              size="1.5vw"
              color="#000"
              case="uppercase"
            />
           
            <div
              css={choose == "Adarsh Savana" ? one : common}
              onClick={chooseSavana}
            >
              <span css={final}>Savana</span>
            </div>
            <div
              css={choose == "Adarsh Park heights" ? one : common}
              onClick={chooseParkHeights}
            >
              <span css={final}><Link to="/adarsh-park-heights">Park Heights</Link></span>
            </div>
           
          </div>
          <About />
          <Table />
          <FloorPlanComponet />
          <Amenities />
          <GalleryComponent />
        </Layout>
        </UserStateContext.Provider>
    );
  };

  return <div>{state === false ? displayLogoAtLod() : displayLayout()}</div>;
};

export default IndexPage;

const one = css`
  border: 3px solid #15be53;
  width: 9%;
  text-align: center;
  padding: 30px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%), 0 -18px 60px -10px rgb(0 0 0 / 3%);
    @media (max-width: 600px) {
     width:45%
    }
`;

const common = css`
  text-align: center;
  padding: 30px;
  width: 9%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%), 0 -18px 60px -10px rgb(0 0 0 / 3%);
    @media (max-width: 600px) {
      width:45%
     }
`;

const chooseProjects = css`
  padding: 20px;
  background: 3px 3px/8px 8px #fff
  radial-gradient(rgba(203, 214, 224, 0.35) 18.75%, transparent 0);
  border: 1px solid #cbd6e0;
  text-align:center;
  span {
    color: #0a2540;
    letter-spacing: 0.2px;
    font-size: 1.2vw;
    a{
      text-decoration: none !important;
      color: black;
      :hover{
        text-decoration:underline;
      }
    }
  }
  div{
    display: inline-block;
    margin:30px 20px;
    :hover{
      cursor: pointer;
    }
  }
`;

const HeroStyle = css`
  padding-top: 5vh;
`;

const load = css`
  padding-top: 30vh;
  text-align: center;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    font-size: 6vh;
  }
`;

const final = css`
@media (max-width: 600px) {
  font-size:20px !important;
}
`